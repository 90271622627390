import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import theme from './shell/theme';
import Routes from './shell/routes';
export default function App() {
    return (
        <div id="appwrapper" style={{ color: '#000000' }}>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <Router>
                    <Routes />
                </Router>
            </ThemeProvider>
        </div>
    );
};
