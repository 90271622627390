import AuthService from "../services/authService";

const MESSAGE_KEY = "__MESSAGECOUNT";
const Brands_key = "__Brands"
const CommonService = {
    serverUrl() {
        return "https://stremeadmin.kwworks.net/";
        //return "http://52.240.156.147:8071/";
        //return "https://braidup.azurewebsites.net/";
    },
     ExecuteGet(request,callback) {
         fetch(CommonService.serverUrl()+'api/common/'+request.Url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
        .then(res => {
             if(callback){
                 callback(res);
             }
        }).catch((res) => {
                if (res.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/access/signup";
                }
            });
    },
    ExecutePost(request,body,callback) {
        fetch(CommonService.serverUrl()+'api/common/'+request.Url, {
           method: "Post",
           headers: {
               'Content-Type': 'application/json'
           },
           body: JSON.stringify(body)
       }).then(res => res.json())
       .then(res => {
            if(callback){
                callback(res);
            }
       }).catch((res) => {
               if (res.message === "Unexpected token < in JSON at position 0") {
                   window.location.href = "/access/signup";
               }
           });
   },
setMessageCount(callback){
    if(AuthService.getBraiderId() != null){
        this.ExecuteGet({ Url: "getUnreadMessagesCount?seekerId="+AuthService.getSeekerId()+"&braiderId="+(AuthService.getBraiderId() == null ? undefined : AuthService.getBraiderId())+"&userName="+AuthService.getUserName() }, (res) => {
            localStorage.setItem(MESSAGE_KEY, res.count || 0);
            if(callback){
                callback(res.count || 0);
            }
        });
    }else{
        this.ExecuteGet({ Url: "getUnreadMessagesCount?seekerId="+AuthService.getSeekerId()+"&userName="+AuthService.getUserName() }, (res) => {
            localStorage.setItem(MESSAGE_KEY, res.count || 0);
            if(callback){
                callback(res.count || 0);
            }
        });
    }
    
    
},
setBrands(callback){
        this.ExecuteGet({ Url: "getbrands" }, (res) => {
            localStorage.setItem(Brands_key, JSON.stringify(res) || []);
            if(callback){
                callback(res || []);
            }
        });
},
getBrands(){
    let res = localStorage.getItem(Brands_key) || [];
    return res;
},
getMessageCount(){
    let count = localStorage.getItem(MESSAGE_KEY) || 0
    return count;
},
};
export default CommonService;