import CommonService from "./CommonService";
const STORAGE_KEY = "__USERDATA";
const MESSAGE_KEY = "__MESSAGECOUNT";
const ProfileImage_KEY = "__validateProfileImageKey";
let userData;
const AuthService = {
    
    getUserData() {
        if (userData) {
            return userData || {};
        } else {
            var data = JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}");
            userData = data;
            return data;
        }
    },
    getProfileImageKey() {
        var data = localStorage.getItem(ProfileImage_KEY) || false;
            return data;
    },
    setUserData(data) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    },
    isAuthenticated() {
        return this.getUserData().access_token !== undefined;
    },
    getUserName() {
        return this.getUserData().userName;
    },
    getSeekerId() {
        return this.getUserData().seekerProfileId;
    },
    getBraiderId() {
        return this.getUserData().braiderProfileId;
    },
    getClientId() {
        
        return this.getUserData().clientId;
    },
    getUserId() {
        return this.getUserData().userId;
    },
    getStreet() {
        
        return this.getUserData().Street + this.getUserData().Street2;
    },
    getArea() {
       
        return this.getUserData().area;
    },
    getCity() {
        
        return this.getUserData().city;
    },
    getPostalCode() {
        return this.getUserData().postalCode;
    },
    getUserType() {
        return this.getUserData().type;
    },
    getEmail() {
        
        return this.getUserData().email;
    },
    getDisplayName() {
        return this.getUserData().displayName;
    },
    getBusinessName() {
        return this.getUserData().businessName;
    },
    getFirstName() {
        return this.getUserData().firstName;
    },
    getFirstLetter() {
        return this.getUserData().firstName[0];
    },
    getProfileImageURL() {
        return "https://webuploadtest.blob.core.windows.net/profileimages/";
    },
    getFullName() {
        var userData = this.getUserData();
        return userData.firstName + " " + userData.lastName;
    },
    getLastName() {
        return this.getUserData().lastName;
    },
    getPhone() {
        return this.getUserData().phone;
    },
    getEmailConfirmed() {
        return this.getUserData().emailconfirmed;
    },
    getIsConfirmed() {
        
        return this.getUserData().isConfirmed;
    },
    getAuthToken() {
        return "Bearer " + this.getUserData().access_token;
    },
    signIn(username, password, success, fail,callback) {
        this.signOut()
        fetch(CommonService.serverUrl()+'api/authenticate/oauth', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({UserName:username, password:password})
        }).then(res => res.json())
            .then(res => {
                if (res.errors.length > 0) {
                    if(callback)callback(res);

                } else {
                    
                    localStorage.setItem(STORAGE_KEY, JSON.stringify(res?.userData));
                    if(callback)callback(res);
                    return res;
                }
            })
            .catch(res => {
            });
    },
    setProfileKeyFalse() {
        localStorage.setItem(ProfileImage_KEY, false);
    },
    validateProfileImage() {
        return  fetch('https://webuploadtest.blob.core.windows.net/profileimages/' + this.getUserId() + '.png')
            
            .then(respone => {
                if (respone.ok) {
                    localStorage.setItem(ProfileImage_KEY, true);
                   // success(respone);
                    
                } else {
                    
                    localStorage.setItem(ProfileImage_KEY, false);
                    //fail(respone);
                    
                }
            })
            .catch((respone) => {
                //if (respone.message === "Unexpected token < in JSON at position 0") {
                //    
                //}
            });
    },
    registerUser(request,callback) {
       
        return fetch(CommonService.serverUrl()+"api/authenticate/saveseeker", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        }).then(res => res.json())
        .then(res => {
            if (res.errors.length > 0) {
                if(callback)callback(res);

            } else {
                if(callback)callback(res);
                return res;
            }
        })
        .catch(res => {
            if(callback)callback(res);
        });
            
    },
    registerBraider(request,callback) {
        return fetch(CommonService.serverUrl()+"api/authenticate/savebraider", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        }).then(res => res.json())
        .then(res => {
            if (res.errors.length > 0) {
                if(callback)callback(res);

            } else {
                if(callback)callback(res);
                return res;
            }
        })
        .catch(res => {
            if(callback)callback(res);
        });
    },
    addUser(request) {
       
        return fetch('/api/accounts/addUser', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        }).then(res => res.json())
            .catch((res) => {
                if (res.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/login";
                }
            });
    },
    ResendConfirmEmail(request) {
       
        return fetch('/api/accounts/ResendConfirmEmail', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        }).then(res => res.json())
            .catch((res) => {
                if (res.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/login";
                }
            });
    },
    forgotPasswordEmail(request) {
        return fetch('/api/accounts/SendEmailForPasswordReset', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify(request),
        }).then(res => res.json())
            .catch((res) => {
                if (res.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/forgetpassword";
                }
            });
    },
    changePassword(request) {
        return fetch('/api/accounts/ChangeUserPassword', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                
            },
            body: JSON.stringify(request),
        }).then(res => res.json())
            .catch((res) => {
                if (res.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/login";
                }
            });
    },
    ConfirmedEmail(request) {
        return fetch('/api/accounts/ConfirmedEmail', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        }).then(res => res.json())
            .catch((res) => {
                //if (res.message === "Unexpected token < in JSON at position 0") {
                //   
                //}
            });
    },
    updateUser(request, success, fail) {
       
        return fetch('/api/accounts/UpdateUser', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify(request),
        }).then(res => res.json())
            .then(res => {
                
                if (!res.IsSuccess) {
                    fail(res);
                } else {
                  
                    //var oldUserData = this.getUserData().data;
                    var newUserData = res.Data;
                    var resData = Object.assign(userData, newUserData)
                    localStorage.setItem(STORAGE_KEY, JSON.stringify(resData));
                    success(res);
                }
            })
            .catch((res) => {

                //if (res.message === "Unexpected token < in JSON at position 0") {
                //    window.location.href = "/login";
                //}
            });
    },
    updateProfileImage(file,fileName,callback) {
        let form = new FormData();
        if(fileName == ""){
            fileName = this.generateUUID()
        }
        form.append('file', file, fileName);
        return fetch(CommonService.serverUrl()+"api/storage/uploadimage", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
            },
            body: form,
        }).then(res => res.json())
        .then(res => {
            if (res.isSuccess == true) {
                if(callback)callback(res);
            } else {
                if(callback)callback(res);
                return res;
            }
        })
        .catch(res => {
        });
    },
    deleteProfileImage(request) {

        return fetch('/api/accounts/DeleteProfileImage', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify(request),
        }).then(res => res.json())
            .catch((res) => {
                if (res.message === "Unexpected token < in JSON at position 0") {
                    window.location.href = "/login";
                }
            });
    },
    SaveProfileInfo(request, success, fail) {
        
        return fetch('/api/accounts/SaveProfileInfo', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify(request),
        }).then(res => res.json())
            .then(res => {
                
                if (!res.IsSuccess) {
                    fail(res);
                } else {

                    //var oldUserData = this.getUserData().data;
                    var newUserData = res.Data;
                    var resData = Object.assign(userData, newUserData)
                    localStorage.setItem(STORAGE_KEY, JSON.stringify(resData));
                  
                    success(res);
                }
            })
            .catch((res) => {

                //if (res.message === "Unexpected token < in JSON at position 0") {
                //    window.location.href = "/login";
                //}
            });
    },
    signOut() {
        userData = null;
        //sessionStorage.clear();
        //localStorage.clear();
        localStorage.removeItem(STORAGE_KEY);
        localStorage.removeItem("NotificationKey");
        localStorage.removeItem("ConnectionKey");
        localStorage.removeItem(MESSAGE_KEY);
    },
    hardRefresh() {
        userData = null;
        localStorage.removeItem(STORAGE_KEY);
    },
     generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
};
export default AuthService;