import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import FallbackLoader from "../elements/FallbackLoader";

// Lazy Components
const Welcome = lazy(() => import('../components/Welcome'));
const Access = lazy(() => import('../components/Access'));
const Legal = lazy(() => import('../components/Legal'));
const ForgotPassword = lazy(() => import('../components/ForgotPassword'));
const PasswordResetEmailSent = lazy(() => import('../components/PasswordResetEmailSent'));
const Start = lazy(() => import('../components/userprofile/Start'));
const Wizard = lazy(() => import('../components/userprofile/Wizard'));
const BraiderStart = lazy(() => import('../components/braiderprofile/Start'));
const BraiderWizard = lazy(() => import('../components/braiderprofile/wizard'));
const BraiderSubmission = lazy(() => import('../components/braiderprofile/Submission'));
const Guidelines = lazy(() => import('../components/braiderprofile/Guidelines'));
const Home = lazy(() => import('../components/Home'));
const Search = lazy(() => import('../components/Search/Search'));
const SearchDetail = lazy(() => import('../components/Search/SearchDetail'));
const Inbox = lazy(() => import('../components/communication/Inbox'));
const CreateMessage = lazy(() => import('../components/communication/CreateMessage'));
const ReadMessage = lazy(() => import('../components/communication/ReadMessage'));
const SentMessage = lazy(() => import('../components/communication/SentMessage'));
const PrivacyPolicy = lazy(() => import('../components/userprofile/PrivacyPolicy'));
const TermsOfService = lazy(() => import('../components/userprofile/TermsOfService'));
const ContactBraider = lazy(() => import('../components/communication/CreateMessage'));
const Favourites = lazy(() => import('../components/Search/Favorites'));
const ConfirmAppointment = lazy(() => import('../components/appointment/ConfirmAppointment'));
const Profile = lazy(() => import('../components/Profile'));
const ProfileStyles = lazy(() => import('../components/ProfileEdits/ProfileStyles'));
const ResetPassword = lazy(() => import('../components/ProfileEdits/ResetPassword'));
const ProfileBudget = lazy(() => import('../components/ProfileEdits/ProfileBudget'));
const ProfileGender = lazy(() => import('../components/ProfileEdits/ProfileGender'));
const ProfileName = lazy(() => import('../components/ProfileEdits/ProfileName'));
const ProfileDisplayName = lazy(() => import('../components/ProfileEdits/ProfileDisplayName'));
const ProfileOwnerName = lazy(() => import('../components/ProfileEdits/ProfileOwnerName'));
const ProfileBusinessName = lazy(() => import('../components/ProfileEdits/ProfileBusinessName'));
const ProfileStatesSeeker = lazy(() => import('../components/ProfileEdits/ProfileStatesSeeker'));
const ProfileStatesBraider = lazy(() => import('../components/ProfileEdits/ProfileStatesBraider'));
const ProfileRequests = lazy(() => import('../components/ProfileEdits/ProfileRequests'));
const RequestAppointment = lazy(() => import('../components/appointment/RequestAppointment'));
const RescheduleAppointment = lazy(() => import('../components/appointment/RescheduleAppointment'));
const SendAppointment = lazy(() => import('../components/appointment/SendAppointment'));
const ListAppointment = lazy(() => import('../components/appointment/ListAppointment'));
const DeclineAppointment = lazy(() => import('../components/appointment/DeclineAppointment'));
const Success = lazy(() => import('../components/userprofile/Success'));

export default function Routes(props) {
    return (
        <Suspense fallback={<FallbackLoader allowimage={true} allowloader={true}/>}>
            <Switch>
                    <Route exact sensitive path="/access/:id" component={Access}/>
                    <Route exact sensitive path="/forgotpassword" component={ForgotPassword}/>
                    <Route exact sensitive path="/passwordresetemailsent" component={PasswordResetEmailSent}/>
                    <Route exact sensitive path="/startseeker" component={Start}/>
                    <Route exact sensitive path="/signupseeker" component={Wizard} />
                    <Route exact sensitive path="/startbraider/:id" component={BraiderStart}/>
                    <Route exact sensitive path="/signupbraider/:id" component={BraiderWizard}/>
                    <Route exact sensitive path="/braiderprofile/submission" component={BraiderSubmission}/>
                    <Route exact sensitive path="/guidelines" component={Guidelines}/>
                    <Route exact sensitive path="/home" component={Home}/>
                    <Route exact sensitive path="/search" component={Search}/>
                    <Route exact sensitive path="/inbox" component={Inbox}/>
                    <Route exact sensitive path="/createmessage" component={CreateMessage}/>
                    <Route exact sensitive path="/readmessage/:id" component={ReadMessage} />
                    <Route exact sensitive path="/sentmessage/:id" component={SentMessage} />
                    <Route exact sensitive path="/privacy" component={PrivacyPolicy} />
                    <Route exact sensitive path="/terms" component={TermsOfService} />
                    <Route exact sensitive path="/braiderdetail/:id" component={SearchDetail} />
                    <Route exact sensitive path="/favourites" component={Favourites} />
                    <Route exact sensitive path="/contactbraider/:id/:name" component={ContactBraider} />
                    <Route exact sensitive path="/confirmappointment" component={ConfirmAppointment} />
                    <Route exact sensitive path="/requestappointment/:sid/:bid/:name" component={RequestAppointment} />
                    <Route exact sensitive path="/resheduleappointment/:sid/:bid/:name" component={RescheduleAppointment} />
                    <Route exact sensitive path="/confirmappointment/:mid/:name" component={SendAppointment} />
                    <Route exact sensitive path="/replymessage/:name/:sub/:sid/:bid" component={CreateMessage}/>
                    <Route exact sensitive path="/profile" component={Profile} />
                    <Route exact sensitive path="/profile/name/:id" component={ProfileName}/>
                    <Route exact sensitive path="/profile/displayname" component={ProfileDisplayName}/>
                    <Route exact sensitive path="/profile/ownername" component={ProfileOwnerName}/>
                    <Route exact sensitive path="/profile/businessname" component={ProfileBusinessName}/>
                    <Route exact sensitive path="/profile/styles/:id" component={ProfileStyles} />
                    <Route exact sensitive path="/profile/changepassword" component={ResetPassword}/>
                    <Route exact sensitive path="/profile/budget/:id" component={ProfileBudget}/>
                    <Route exact sensitive path="/profile/gender/:id" component={ProfileGender}/>
                    <Route exact sensitive path="/profile/locations" component={ProfileStatesSeeker}/>
                    <Route exact sensitive path="/profile/location" component={ProfileStatesBraider}/>
                    <Route exact sensitive path="/profile/requests/:id" component={ProfileRequests}/>

                    <Route exact sensitive path="/" component={Welcome}/>
                    <Route exact sensitive path="/listappointment" component={ListAppointment}/>
                    <Route exact sensitive path="/success/:id" component={Success}/>
                    <Route exact sensitive path="/decline/:id" component={DeclineAppointment} />
                    <Route exact sensitive path="/legal" component={Legal}/>
                    <Route path=""><NotFound /></Route>
            </Switch>
        </Suspense>
    );
}

function NotFound() {
    return (
        <div>
            <h2>Not Found</h2>
        </div>
    );
}

