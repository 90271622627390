import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import AuthService from "./services/authService";
import CommonService from './services/CommonService';


var app = null;
export const initializeFirebase = () => {
    var firebaseConfig = {
        apiKey: "AIzaSyBoknEVZzXqSIHf-v_jANhUALaEA8tmST4",
        authDomain: "braidup-7c16c.firebaseapp.com",
        projectId: "braidup-7c16c",
        storageBucket: "braidup-7c16c.appspot.com",
        messagingSenderId: "678864012279",
        appId: "1:678864012279:web:393c23a99b8492455824f2",
        measurementId: "G-W7437EW1BD"
    };
    app = initializeApp(firebaseConfig);

    console.log('Initialization Done')
}

export const askForPermissionToReceiveNotifications = async () => {

    requestPermission();
}

function getMessagingToken() {
    const messaging = getMessaging(app);
    getToken(messaging, { vapidKey: 'BFWgBIbODRrEMZIOL-OnKhjx0K4smoxF32bemKHtcp_CxbGJsxmM4fVdipzWEKGsTsSb0EDNByirBc5KlC9pVUg' }).then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            CommonService.ExecuteGet({ Url: "setfirebasetoken?userId=" + AuthService.getUserId() + "&token=" + currentToken }, (res) => {
                if(res.isSuccess) {
                    console.log('Token Sent Successfully:', currentToken)
                }
                else {
                    console.log(res.message)
                }
            });
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
}

function requestPermission() {
    console.log('Requesting Notification Permission');
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification Permission Granted.');

            getMessagingToken();
            // TODO(developer): Retrieve a registration token for use with FCM.
            // In many cases once an app has been granted notification permission,
            // it should update its UI reflecting this.
            //resetUI();
        } else {
            console.log('Unable to get permission to notify.');
        }
    });
}